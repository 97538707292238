.square-radio
{
    cursor: pointer;
    border: 2px solid #0b0c0c;
    padding: 6px 0px;
    width: 120px;
    text-align: center;
    margin-right: 10px;

    &:hover
    {
        outline: 3px solid #ffdf0f;
        outline-offset: 0;
    }

    &.checked
    {
        background-color: #76B852;
        color: #fff;
    }

    input[type=radio]
    {
        display: none;
    }
}