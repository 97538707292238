@use 'externalStyle';
@use 'scss/fonts';
@use 'scss/app';

// ---------------------- COMMON ----------------------
*, *:focus
{
    outline: none;
}

*
{
    box-sizing: border-box;
}

html
{
    // height: 100%;
}

body
{
    // overflow: hidden;
    margin: 0;
    padding: 0;
    // height: 100%;
}

app
{
    display: flex;
    // height: 100vh;
    flex-direction: column;

    router-outlet+*, router-outlet+*+*
    {
        @extend .thin-scrollbar;

        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
        min-width: 0;
        padding: 30px 0 5px;

        &.scrollable
        {
            overflow-y: scroll;
        }
    }
}

.no-ie, .no-grid
{
    display: none;
}

.no-arrow
{
    .no-ie
    {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.7em;
    }

    app
    {
        display: none;
    }
}

.no-cssgrid.arrow
{
    .no-grid
    {
        display: block;
    }
}

// ---------------------- SPLASH SCREEN ----------------------

.loading-indicator
{
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 23456;
    opacity: 1;

    & > div
    {
        border: 4px solid #eee;
        border-bottom: none;
        border-top-left-radius: 48px;
        border-top-right-radius: 48px;
        height: 24px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 50%;
        width: 48px;
        animation-name: rotate-loading;
        animation-duration: 450ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: calc(50%) calc(50% + 12px);
    }
}

@keyframes rotate-loading
{
    0%
    {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
}

.validation-error-div
{
    &>div
    {
        color: red;
    }
}