@use 'sass:color';
@use 'sass:map';

@use 'vars';

@mixin generate-margin-padding($type, $sizes)
{
    @each $sizeType, $size in $sizes
    {
        .#{$type}-#{$sizeType}
        {
            #{$type}: $size;
        }

        .#{$type}-right-#{$sizeType}
        {
            #{$type}-right: $size;
        }

        .#{$type}-left-#{$sizeType}
        {
            #{$type}-left: $size;
        }

        .#{$type}-top-#{$sizeType}
        {
            #{$type}-top: $size;
        }

        .#{$type}-bottom-#{$sizeType}
        {
            #{$type}-bottom: $size;
        }

        .#{$type}-horizontal-#{$sizeType}
        {
            #{$type}-left: $size;
            #{$type}-right: $size;
        }

        .#{$type}-vertical-#{$sizeType}
        {
            #{$type}-top: $size;
            #{$type}-bottom: $size;
        }
    }
}

@mixin generate-button($colors)
{
    @each $name, $color in $colors
    {
        
        $background: map-get($color, "background");
        $foreground: map-get($color, "foreground");

        .btn-#{$name}
        {
            background-color: $background;
            border-color: $background;
            color: $foreground;

            &:active:not(.disabled)
            {
                background-color: color.scale($background, $lightness: -18%);
                border-color: color.scale($background, $lightness: -18%);
            }

            &:hover,
            &:focus
            {
                background-color: color.scale($background, $lightness: -12%);
                border-color: color.scale($background, $lightness: -12%);
            }

            &>span
            {
                color: $foreground;
            }

            &>span[class^="fa"],
            &>span[class^="fas"],
            &>span[class^="glyphicon"]
            {
                color: color.scale($background, $lightness: -26%);
            }
        }
    }
}

@mixin generate-alert($colors)
{
    @each $name, $color in $colors
    {
        $background: map-get($color, "background");
        $foreground: map-get($color, "foreground");

        .alert-#{$name}
        {
            background-color: color.scale($background, $lightness: 22%);
            color: $foreground;
            border-color: $background;
        }
    }
}

@mixin thin-scrollbar-color($theme, $color, $lightness: -30%)
{
    @if $lightness 
    {
        .thin-scrollbar
        {
            scrollbar-color: color.scale(map.get($theme, $color), $lightness: $lightness) transparent;
        }

        .thin-scrollbar::-webkit-scrollbar-thumb
        {
            background-color: color.scale(map.get($theme, $color), $lightness: $lightness);
        }
    }
    @else
    {
        .thin-scrollbar
        {
            scrollbar-color: color.scale(map.get($theme, $color), $lightness: $lightness) transparent;
        }

        .thin-scrollbar::-webkit-scrollbar-thumb
        {
            background-color: color.scale(map.get($theme, $color), $lightness: $lightness);
        }
    }
}