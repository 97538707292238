/* Inter */
@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-Thin.ttf");
    font-weight: 100;
}

@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-ExtraLight.ttf");
    font-weight: 200;
}

@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-Light.ttf");
    font-weight: 300;
}

@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-Regular.ttf");
    font-weight: 400;
}

@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-Medium.ttf");
    font-weight: 500;
}

@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-SemiBold.ttf");
    font-weight: 600;
}

@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-Bold.ttf");
    font-weight: 700;
}

@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-ExtraBold.ttf");
    font-weight: 800;
}

@font-face 
{
    font-family: "Inter";
    src: url("external/fonts/Inter/Inter-Black.ttf");
    font-weight: 900;
}

/* Source Sans Pro */
@font-face
{
    font-family: "Source Sans Pro";
    src: url("external/fonts/SourceSansPro/SourceSansPro-Regular.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
}

@font-face
{
    font-family: "Source Sans Pro";
    src: url("external/fonts/SourceSansPro/SourceSansPro-Bold.woff") format("woff");
    font-style: normal;
    font-weight: bold;
    font-display: fallback;
}