@use 'sass:map';

@use '../../mixins';
@use '../mixins/grid.mixins' as grid;

$theme: null !default;

//############## BLOCKS ##############

.popup-block
{
    box-shadow: 0 0 3px map.get($theme, popupBorder);
    background-color: map.get($theme, popupBackground);
}

.primary-block
{
    @include mixins.thin-scrollbar-color($theme, "primaryBackground");

    background-color: map.get($theme, primaryBackground);
    color: map.get($theme, primaryForeground);
}

.secondary-block
{
    @include mixins.thin-scrollbar-color($theme, "secondaryBackground");

    background-color: map.get($theme, secondaryBackground);
    color: map.get($theme, secondaryForeground);
}

.highlight-block
{
    @include mixins.thin-scrollbar-color($theme, "blockBackground");

    background-color: map.get($theme, blockBackground);
    color: map.get($theme, blockForeground);

    ng-grid
    {
        div.css-grid-content-renderer
        {
            .content-renderer-header > div
            {
                border-color: map.get($theme, hightlightGridHeaderBackground);
            }

            .fixed-header
            {
                background-color: map.get($theme, hightlightGridHeaderBackground);
                color: map.get($theme, hightlightGridHeaderForeground);
            }

            .content-renderer-body > div:nth-of-type(2n+1) > div
            {
                background-color: map.get($theme, hightlightGridEvenRowBackground);
            }
        }

        @include grid.paging-full-coloring(map.get($theme, highlightPrimaryPagingBackground),
                                           map.get($theme, highlightPrimaryPagingForeground),
                                           map.get($theme, highlightPrimaryPagingDisabledBackground),
                                           map.get($theme, highlightPrimaryPagingDisabledForeground),
                                           map.get($theme, highlightSecondaryPagingBackground),
                                           map.get($theme, highlightSecondaryPagingForeground));
    }
}