// ---------------------- MISCELANEOUS ----------------------
.pointer-cursor,
.cursor-pointer
{
    cursor: pointer;
}

.not-allowed-cursor,
.cursor-not-allowed
{
    cursor: not-allowed;
}

.text-center
{
    text-align: center;
}

.text-right
{
    text-align: right;
}

.text-left
{
    text-align: left;
}

.block
{
    display: block;
}

.hidden
{
    display: none;
}

.inline-block
{
    display: inline-block;
}

.flex
{
    display: flex;
}

.contents
{
    display: contents;
}

.flex-row
{
    display: flex;
    flex-direction: row;
}

.flex-end
{
    justify-content: flex-end;
}

.flex-row-reverse
{
    flex-direction: row-reverse;
}

.flex-wrap
{
    flex-wrap: wrap;
}

.flex-column
{
    display: flex;
    flex-direction: column;
}

.flex-1
{
    flex: 1;
    min-width: 0;
    min-height: 0;
}

.flex-2
{
    flex: 2;
    min-width: 0;
    min-height: 0;
}

.flex-3
{
    flex: 3;
    min-width: 0;
    min-height: 0;
}

.flex-4
{
    flex: 4;
    min-width: 0;
    min-height: 0;
}

.flex-5
{
    flex: 5;
    min-width: 0;
    min-height: 0;
}

.grid-whole-row
{
    grid-column: 1 / -1;
    min-width: 0;
}

.align-self-center
{
    align-self: center;
}

.align-self-stretch
{
    align-self: stretch;
}

.align-content-start
{
    align-content: flex-start;
}

.align-items-center
{
    align-items: center;
}

.justify-self-center
{
    justify-self: center;
}

.justify-content-end
{
    justify-content: end;
}

.justify-content-center
{
    justify-content: center;
}

.justify-content-space-evenly
{
    justify-content: space-evenly;
}

.relative
{
    position: relative;
}

.absolute
{
    position: absolute;
}

.left-0
{
    left: 0;
}

.top-0
{
    top: 0;
}

.right-0
{
    right: 0;
}

.bottom-0
{
    bottom: 0;
}

.va-middle
{
    vertical-align: middle;
}

.va-top
{
    vertical-align: top;
}

.content-wrap
{
    white-space: normal;
}

.content-nowrap
{
    white-space: nowrap;
}

.max-width-none
{
    max-width: 0;
}

.min-width-none
{
    min-width: 0;
}

.min-height-none
{
    min-width: 0;
}

.min-dimensions-none
{
    min-width: 0;
    min-height: 0;
}

.max-height-full
{
    max-height: 100%;
}

.half-width
{
    width: 50%;
}

.full-width
{
    width: 100%;
}

.full-height
{
    height: 100%;
}

.quarter-width
{
    width: 25%;
}

.third-width
{
    width: 33.33%;
}

.text-selection
{
    user-select: text;
}

.semi-bold
{
    font-weight: 500;
}

.bold
{
    font-weight: bold;
}

.italic
{
    font-style: italic;
}

.text-small-caps
{
    font-variant-caps: small-caps;
}

.text-uppercase
{
    text-transform: uppercase;
}

.first-letter-uppercase::first-letter
{
    text-transform: uppercase;
    line-height: 0em;
}

.text-capitalize
{
    text-transform: capitalize;
}

.text-ellipsis
{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.line-height-1em
{
    line-height: 1em;
}

.font-1em
{
    font-size: 1em;
}

.font-bigger-sm
{
    font-size: 1.1em;
}

.font-bigger-md
{
    font-size: 1.2em;
}

.thin-scrollbar
{
    scrollbar-width: thin;
}

.overflow-hidden
{
    overflow: hidden;
}

.overflow-auto
{
    overflow: auto;
}

.overflow-vertical-auto
{
    overflow-y: auto;
}

.overflow-vertical-scroll
{
    overflow-y: scroll;
}

.table-container
{
    overflow-x: auto;
}

.notransition
{
    transition: none !important;
}

.transition-all-400
{
    transition: all 400ms;
}

.file-upload.input-group
{
    overflow: hidden;
    position: relative;

    &>input[type="text"]
    {
        font-size: 14px;
        height: 34px;
        padding: 6px 12px;
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;
        z-index: 0;
    }

    &>input[type="file"]
    {
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

@media print
{
    .print-hidden
    {
        display: none;
    }
}