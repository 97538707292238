@use '../vars';

//############## IDSK PAGING ##############

ng-grid div.table-content-renderer
{
    overflow-x: auto;
}

ng-basic-paging
{
    &>div
    {
        display: flex;
        flex-direction: row;

        &>ul
        {
            flex: 1;
        }
    }

    ul
    {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;

        &>li
        {
            list-style: none;
        }
    }

    .items-count
    {
        line-height: 1em;
    }
}

//############## BUTTONS ##############

button.govuk-button.small
{
    margin-bottom: 0;
    padding: 2px 7px 3px;
}

//############## IDSK overrides ##############

.govuk-width-container-reports.flex-1
{
    min-width: 0;
    min-height: 0;
}

//############## IDSK responsivity ##############

@media screen and (max-width: vars.$responsiveTrasholdMd)
{
    ng-grid table tbody
    {
        font-size: 0.85em;
    }
}

@media screen and (max-width: vars.$responsiveTrasholdSm)
{
    ng-grid table tbody
    {
        font-size: 0.75em;
    }
}

@media screen and (max-width: vars.$responsiveTrasholdXs)
{
    ng-grid table tbody
    {
        font-size: 0.65em;
    }
}
