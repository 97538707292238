@mixin paging-coloring($background,
                       $foreground,
                       $disabledBackground,
                       $disabledForeground,
                       $activeBackground,
                       $activeForeground)
{
    li
    {
        margin: 0 3px;

        a
        {
            background-color: $background;
            color: $foreground;
        }

        &.disabled a
        {
            background-color: $disabledBackground;
            color: $disabledForeground;
        }

        &.active a
        {
            // background-color: $activeBackground;
            // color: $activeForeground;
        }
    }
}

@mixin paging-full-coloring($background,
                            $foreground,
                            $disabledBackground,
                            $disabledForeground,
                            $activeBackground,
                            $activeForeground)
{
    ng-basic-paging
    {
        &>div
        {
            &>ul
            {
                @include paging-coloring($background,
                                         $foreground,
                                         $disabledBackground,
                                         $disabledForeground,
                                         $activeBackground,
                                         $activeForeground);
            }
    
            &>div
            {
                ul
                {
                    @include paging-coloring($background,
                                             $foreground,
                                             $disabledBackground,
                                             $disabledForeground,
                                             $activeBackground,
                                             $activeForeground);
                }
            }
        }
    }
}