@use 'sass:map';

$theme: null !default;

//############## MAIN MENU ##############

main-menu
{
    .nav-links
    {
        a
        {
            color: map.get($theme, onPrimary);
        }

        a.active,
        a.active:visited
        {
            color: map.get($theme, onPrimaryVariant) !important;
        }
    }
}

//############## BUTTONS ##############

.govuk-button
{
    background-color: map.get($theme, primary);
}